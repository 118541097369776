import React, { useEffect } from 'react';

import { clientConfigurationSelector } from '../redux/pages/selectors';
import { useDispatch, useSelector } from 'react-redux';
import {
  configNameSelector,
  isLoggedInSelector,
} from '../redux/auth/selectors';
import { fetchClientConfig } from '../redux/pages/actions';
import LoggedInRoute from './LoggedInRoute';
import GeneralPage from '../components/general/GeneralPage';
import GeneralComponentErrorShield from '../components/general/GeneralComponentErrorShield';
import axios from 'axios';

const Pages = () => {
  const dispatch = useDispatch();

  // in case the first page in a section isn't the overview
  const isAuthenticated = useSelector(isLoggedInSelector);

  const configName = useSelector(configNameSelector);

  const clientConfig = useSelector(clientConfigurationSelector);

  const cancelToken = axios.CancelToken;
  const source = cancelToken.source();
  useEffect(() => {
    if (isAuthenticated && configName) {
      dispatch(fetchClientConfig(configName, source));
    }
  }, [isAuthenticated]);

  const configError = `No client configuration for ${configName} was detected. Please contact the RiskSystem administrator.`;

  return (
    <GeneralComponentErrorShield
      dataObjects={clientConfig}
      customErrorMessage={configError}
      customLoadingMessages={['Loading your Raptor configuration...']}
    >
      <LoggedInRoute Component={GeneralPage} />
    </GeneralComponentErrorShield>
  );
};
export default React.memo(Pages);
