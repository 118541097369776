import {
  Navigate,
  RouteObject,
  createBrowserRouter,
  useNavigate,
  useParams,
} from 'react-router-dom';
import Pages from './Pages';
import LoggedOutRoute from './LoggedOutRoute';
import LoginPage from '../components/pages/auth/LoginPage';
import SignupPage from '../components/pages/auth/SignupPage';
import ResetPassword from '../components/pages/auth/ResetPassword.component';
import DocumentationViewer from '../components/documentation/DocumentationViewer.component';
import MyAccount from '../components/settings/pages/myAccount/MyAccount.component';
import SecuritySettings from '../components/settings/pages/securitySettings/SecuritySettings.component';
import PageNotFound from '../components/settings/pages/PageNotFound.component';
import SettingsLayout from '../components/layout/layouts/SettingsLayout.component';
import DocumentationLayout from '../components/layout/layouts/DocumentationLayout.component';
import Dashboard from '../components/pages/dashboard/Dashboard';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clientSectionsSelector } from '../redux/pages/selectors';
import axios from 'axios';
import { fetchClientConfig } from '../redux/pages/actions';
import {
  configNameSelector,
  isLoggedInSelector,
} from '../redux/auth/selectors';
import ManageAccounts from '../components/settings/pages/manageAccounts/ManageAccounts.component';
import GeneralLayout from '../components/layout/layouts/GeneralLayout.component';
import AuthLayout from '../components/layout/layouts/AuthLayout';
import { RaidrRoutes } from './Raidr.routes';
import LoggedInRoute from './LoggedInRoute';

// Logic from the original file into a component - it deals with the fact there are no sections and that clicking the nav should open the first page that of that section
export const UnconventionalRedirect = ({
  hardSection,
}: {
  hardSection?: string;
}) => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(isLoggedInSelector);

  const { section } = useParams(); // Get the section ID from the URL
  const navigate = useNavigate();
  const configName = useSelector(configNameSelector);

  const cancelToken = axios.CancelToken;
  const source = cancelToken.source();
  useEffect(() => {
    if (isAuthenticated && configName) {
      dispatch(fetchClientConfig(configName, source));
    }
  }, [isAuthenticated]);

  const usedSection = hardSection || section;

  const clientSections = useSelector(clientSectionsSelector);

  useEffect(() => {
    if (!usedSection || !clientSections) return;

    // Find the matching section
    const matchingSection = clientSections.find(
      (s: any) => s.sectionId === usedSection,
    );

    if (matchingSection && matchingSection.pages?.length > 0) {
      // Redirect to the first page within the section
      navigate(`/${matchingSection.sectionId}/${matchingSection.pages[0]}`, {
        replace: true,
      });
    }
  }, [usedSection, clientSections, navigate]);

  // Optionally, render a loading or fallback UI during redirection
  return <div>Redirecting...</div>;
};

// Data can eventually be the context of the user - however when using the TokenService we can call this from anywhere in the app howwever passing a prop is useful for application data being passed to loader/action functions
export function makeRoutes(data: any): RouteObject[] {
  return [
    {
      element: <GeneralLayout />,
      children: [
        {
          index: true,
          path: '',
          element: <Navigate to="/dashboard" replace />,
        },
        {
          id: 'dashboard',
          path: '/dashboard',
          element: <LoggedInRoute Component={Dashboard} />,
        },
        {
          path: '/:section',
          element: <UnconventionalRedirect />,
        },
        {
          path: '/:section/:pageId',
          element: <Pages />,
        },
        {
          path: '*',
          element: <PageNotFound />,
        },
      ],
    },

    {
      path: '/settings',
      element: <SettingsLayout />,
      children: [
        {
          path: 'my-account',
          element: <MyAccount />,
        },
        {
          path: 'manage-accounts',
          element: <ManageAccounts />,
        },
        {
          path: 'security-settings',
          element: <SecuritySettings />,
        },
        {
          path: '*',
          element: <PageNotFound />,
        },
      ],
    },
    ...RaidrRoutes(),
    {
      path: '/documentation',
      element: <DocumentationLayout />,
      children: [
        {
          path: '',
          index: true,
          element: <LoggedInRoute Component={DocumentationViewer} />,
        },
        {
          path: 'introduction',
          element: <LoggedInRoute Component={DocumentationViewer} />,
        },

        {
          path: 'risk',
          children: [
            {
              index: true,
              path: 'overview',
              element: <LoggedInRoute Component={DocumentationViewer} />,
            },

            {
              path: 'exposure',
              element: <LoggedInRoute Component={DocumentationViewer} />,
            },
            {
              path: 'value-at-risk',
              element: <LoggedInRoute Component={DocumentationViewer} />,
            },
            {
              path: 'prospectus-restrictions',
              element: <LoggedInRoute Component={DocumentationViewer} />,
            },
            {
              path: 'counterparty-exposure',
              element: <LoggedInRoute Component={DocumentationViewer} />,
            },
          ],
        },
      ],
    },

    {
      path: '/',
      element: <AuthLayout />,
      children: [
        { path: 'login', element: <LoggedOutRoute Component={LoginPage} /> },
        { path: 'signup', element: <LoggedOutRoute Component={SignupPage} /> },
        {
          path: 'reset-password',
          element: <LoggedOutRoute Component={ResetPassword} />,
        },
      ],
    },
    {
      path: '*',
      element: <PageNotFound />,
    },
  ];
}

export const router = (data: any) =>
  createBrowserRouter(makeRoutes(data), { basename: '/raptor' });
