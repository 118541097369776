import { FC, FunctionComponent, useState } from 'react';
import { FundInfoComponentProps } from '../../../general/GeneralFundInfoPage';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import useFetchData from '../../../../hooks/useFetchData';
import SubscriptionsRedemptionsTable from './subscriptionsRedemptionsTable.component';
import SubscriptionsRedemptionsChart from './subcsriptionsRedemptionsChart.component';
import { makeUniqueDataKey } from '../../../../utilities/urlBuilder';
import { Button, Grid, Switch } from '@mui/material';
import UltraTable, { TableData } from '../../../tables/ultraTable/UltraTable';
import { formatDateForCheckingState } from '../../../../utilities/dateFormatters';
import { ultraTableStyles } from '../../../tables/ultraTable/styles/ultratable.styles';
import { makeStyles, styled } from '@mui/styles';
import { greys, RaptorTheme } from '../../../../styling/theme';
import {
  ALL_SUB_RED_COLS,
  buildColumns,
  buildData,
  SubscriptionRedemptionData,
} from './utils/subscription-redemption-utils';
import { EditableFields } from '../../../tables/ultraTable/components/EditableCell';

export const localStyles = makeStyles<RaptorTheme>((theme) => ({
  typography: {
    color: greys.grey900,
    fontWeight: 300,
    fontSize: '1.6rem',
  },
}));

const SubscriptionsRedemptions: FC<FundInfoComponentProps> = (props) => {
  const { positionDate: activeDate, fundId } = props;

  const urlForFetch = !activeDate
    ? `most_recently_stored_report_data_agg/${props.fundId}/sub_red_risk_data`
    : `stored_report_data_agg/${props.fundId}/sub_red_risk_data/${activeDate}`;

  const subsRedsData = useFetchData({
    keyName: makeUniqueDataKey(props.fundId, 'sub_red', activeDate),
    url: urlForFetch,
    makeFetch: true,
  });

  const absSubsRedsData = subsRedsData?.data?.[0]?.abs_subs_reds_data;

  const positionDate = subsRedsData?.data?.[0]?.selected_position_date;

  return (
    <GeneralComponentErrorShield
      dataObjects={[subsRedsData]}
      customLoadingMessages={['Loading Subscriptions/Redemptions Data...']}
      customErrorMessage="No Data Available."
    >
      <Grid container>
        <SubscriptionsRedemptionsTable
          data={subsRedsData}
          title={'Redemption Risk'}
        />
        <SubscriptionsRedemptionsChart
          data={subsRedsData}
          title={'Redemption Data'}
        />
        <SubscriptionsRedemptionsTable
          data={subsRedsData}
          title={'Gross Activity Risk'}
        />
        <SubscriptionsRedemptionsChart
          data={subsRedsData}
          title={'Gross Activity Data'}
        />

        {absSubsRedsData && (
          <ButlerTableErrorBoundary
            fundId={fundId}
            positionDate={positionDate}
            absSubsRedsData={absSubsRedsData}
          />
        )}
      </Grid>
    </GeneralComponentErrorShield>
  );
};

export default SubscriptionsRedemptions;

const MaterialUISwitch = styled(Switch)<RaptorTheme>(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 122.88 109.93"><path fill="${encodeURIComponent(
          '#fff',
        )}" d="M115.47,65.19c-2.39,12.7-9.16,23.86-18.67,31.85c-9.57,8.04-21.89,12.88-35.33,12.88c-12.63,0-24.28-4.27-33.57-11.45 C18.4,91.14,11.38,80.77,8.27,68.81l6.86-1.78c2.7,10.41,8.82,19.44,17.09,25.83c8.08,6.24,18.22,9.95,29.24,9.95 c11.73,0,22.47-4.21,30.78-11.19c7.95-6.68,13.7-15.91,15.98-26.44h-7.33l10.99-13.39l10.99,13.39H115.47L115.47,65.19z M61.29,69.07V56.52c-4.42-1.12-7.65-2.81-9.7-5.07c-2.06-2.27-3.1-5.02-3.1-8.26c0-3.28,1.17-6.04,3.5-8.26 c2.33-2.23,5.43-3.51,9.3-3.85v-2.95h4.94v2.95c3.62,0.38,6.49,1.47,8.64,3.26c2.13,1.79,3.5,4.19,4.09,7.19l-8.63,0.98 c-0.53-2.36-1.9-3.96-4.1-4.8v11.71c5.46,1.29,9.18,2.98,11.15,5.04c1.98,2.07,2.97,4.72,2.97,7.96c0,3.62-1.24,6.66-3.73,9.14 c-2.49,2.48-5.95,4.02-10.39,4.63v5.6h-4.94v-5.53c-3.9-0.42-7.06-1.69-9.51-3.83c-2.45-2.14-4-5.17-4.68-9.08l8.83-0.92 c0.36,1.6,1.04,2.97,2.04,4.13C58.97,67.72,60.07,68.55,61.29,69.07L61.29,69.07z M61.29,37.6c-1.33,0.42-2.38,1.11-3.15,2.07 c-0.78,0.96-1.16,2.02-1.16,3.18c0,1.06,0.35,2.04,1.06,2.95c0.71,0.9,1.8,1.64,3.26,2.19V37.6L61.29,37.6z M66.23,69.57 c1.7-0.33,3.1-1.05,4.16-2.15C71.47,66.31,72,65.01,72,63.5c0-1.33-0.45-2.49-1.36-3.45c-0.89-0.97-2.36-1.71-4.42-2.23V69.57 L66.23,69.57z M7.46,44.74C9.83,32.15,16.5,21.08,25.87,13.1C35.47,4.93,47.9,0,61.46,0c11.93,0,22.97,3.8,31.98,10.26 c9.25,6.63,16.35,16.06,20.08,27.06l-3.36,1.14l-3.36,1.14c-0.09-0.28-0.19-0.56-0.29-0.83c-3.31-9.21-9.38-17.11-17.2-22.72 c-7.84-5.62-17.45-8.93-27.84-8.93c-11.84,0-22.67,4.28-31.01,11.38c-7.84,6.67-13.49,15.82-15.76,26.24h7.29L10.99,58.13L0,44.74 H7.46L7.46,44.74z"/></svg>')`,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
    width: 32,
    height: 32,
    '&::before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 510 511.91"><path fill="${encodeURIComponent(
        '#fff',
      )}" d="M375.81 301.17 167.58 509.4a8.552 8.552 0 0 1-12.11 0L2.51 356.45a8.564 8.564 0 0 1 0-12.12L210.74 136.1a8.51 8.51 0 0 1 6.06-2.52h83.97C329.63 58.8 366.74 20.67 401.25 6.67c32.8-13.31 63.44-5.58 83.86 13.35 20.29 18.81 30.27 48.72 21.95 79.92-10.07 37.78-47.22 77.46-126.25 99.74-1.92.54-3.86.5-5.65.01l3.13 95.16c.08 2.45-.89 4.69-2.5 6.3l.02.02zm-52.48-167.59h41.39c4.74 0 8.57 3.84 8.57 8.57 0 .15 0 .3-.01.44l1.22 37.01.62-.2c70.54-19.89 103.2-53.39 111.58-84.82 6.2-23.24-1.07-45.37-15.94-59.15-14.74-13.66-37.21-19.12-61.6-9.22-28.86 11.71-60.15 44.01-85.83 107.37zm.52 59.45c1.16 1.33 2 2.94 2.37 4.76 1.03 2.77 1.6 5.76 1.6 8.88 0 7.02-2.85 13.38-7.45 17.98s-10.96 7.45-17.98 7.45-13.37-2.85-17.97-7.45a25.315 25.315 0 0 1-7.45-17.98c0-5.89 2-11.32 5.37-15.63 3.82-14.39 7.89-27.8 12.19-40.31h-74.18L20.69 350.39l140.83 140.83 199.57-199.57-4.63-140.92h-39.67c-3.46 9.66-6.79 19.96-9.99 30.9 5.26.93 9.96 3.45 13.57 7.06 1.31 1.32 2.48 2.77 3.48 4.34z"/></svg>')`,
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
    borderRadius: 20 / 2,
  },
}));

export interface SubscriptionRedemptionDataEditable
  extends SubscriptionRedemptionData,
    EditableFields {}

interface ButlerTableErrorBoundaryProps {
  absSubsRedsData: any;
  positionDate: string;
  fundId: string;
}

const ButlerTableErrorBoundary: FunctionComponent<
  ButlerTableErrorBoundaryProps
> = ({ absSubsRedsData, positionDate, fundId }) => {
  const [viewType, setViewType] = useState<
    'SUBSCRIPTION' | 'REDEMPTION' | null
  >(null);

  const data = absSubsRedsData.filter(
    (row: SubscriptionRedemptionData) =>
      viewType === null || row.type === viewType,
  );

  const tableData: TableData<any> = {
    columns: buildColumns(ALL_SUB_RED_COLS),
    data: buildData(data),
  };

  return (
    <UltraTable<SubscriptionRedemptionDataEditable>
      title="Subscriptions/Redemptions"
      tableData={tableData}
      getRowCanExpand={() => false}
      pdfConfig={{
        pdfIdentifier: `${fundId}_subscriptions_redemptions`,
        pdfTitle: `Subscriptions Redemptions Data - ${fundId}`,
        pdfExportGroupName: 'subscriptions_redemptions_page',
        pdfExportGroupOrder: 5,
        pdfFileName: `subscriptions-redemptions-${fundId}-${
          positionDate || formatDateForCheckingState(new Date())
        }`,
      }}
      actionBtns={[
        <Button
          onClick={() =>
            setViewType((prev) =>
              prev === 'SUBSCRIPTION' ? null : 'SUBSCRIPTION',
            )
          }
          variant={viewType === 'SUBSCRIPTION' ? 'contained' : 'outlined'}
        >
          Subscriptions
        </Button>,
        <Button
          onClick={() =>
            setViewType((prev) => (prev === 'REDEMPTION' ? null : 'REDEMPTION'))
          }
          variant={viewType === 'REDEMPTION' ? 'contained' : 'outlined'}
        >
          Redemptions
        </Button>,
      ]}
    />
  );
};
