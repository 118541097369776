import React, { useEffect } from 'react';
import TopBar from '../topBar/TopBar';
import TitleBar from '../titleBar/Titlebar';
import RaptorSnackbar from '../../feedback/raptorSnackbar/Snackbar.component';
import Sidebar from '../sidebar/Sidebar';
import { zIndexes } from '../../../styling/zIndexes';
import { mainColors } from '../../../styling/theme';
import makeStyles from '@mui/styles/makeStyles';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  configNameSelector,
  isLoggedInSelector,
} from '../../../redux/auth/selectors';
import { fetchClientConfig } from '../../../redux/pages/actions';
import axios from 'axios';

const useStyles = makeStyles({
  body: {
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: mainColors.background,
  },
  main: {
    height: 'calc(100% - 12rem)',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  content: {
    height: '100%',
    width: '100%',
    overflow: 'auto',
    padding: '1rem',
    zIndex: zIndexes.content,
  },
});

const GeneralLayout = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const isAuthenticated = useSelector(isLoggedInSelector);

  const configName = useSelector(configNameSelector);

  const cancelToken = axios.CancelToken;

  const source = cancelToken.source();
  useEffect(() => {
    if (isAuthenticated && configName) {
      dispatch(fetchClientConfig(configName, source));
    }
  }, [isAuthenticated]);

  return (
    <div className={classes.body}>
      <TopBar />
      <TitleBar />
      <main className={classes.main}>
        <Sidebar />
        <div className={classes.content}>
          <Outlet />
        </div>
      </main>
      <RaptorSnackbar />
    </div>
  );
};
export default GeneralLayout;
