import { RouteObject } from 'react-router-dom';
import RAIDRLayout from '../components/layout/layouts/RAIDRLayout.component';
import DelegatedActsEditor from '../components/pages/raidr/delegatedActs/editor/DelegatedActsEditor.component';
import DelegatedActsOverview from '../components/pages/raidr/delegatedActs/overview/DelegatedActsOverview.component';
import FactSheetsEditor from '../components/pages/raidr/factSheets/editor/FactSheetsEditor.component';
import FactSheetsOverview from '../components/pages/raidr/factSheets/overview/FactSheetsOverview.component';
import KidsFigures from '../components/pages/raidr/kidsFigures/KidsFigures.component';
import CompareKIDVersions from '../components/pages/raidr/priips/compareVersions/CompareKIDVersions';
import KidsEditor from '../components/pages/raidr/priips/editor/KidsEditor';
import KidsOverview from '../components/pages/raidr/priips/overview/KidsOverview';
import KidsReview from '../components/pages/raidr/priips/review/KidsReview';
import KidsView from '../components/pages/raidr/priips/view/KidsView';
import PageNotFound from '../components/settings/pages/PageNotFound.component';
import { UnconventionalRedirect } from './Router';

export const RaidrRoutes = (): RouteObject[] => [
  {
    path: '/raidr',
    element: <RAIDRLayout />,
    children: [
      {
        index: true,
        path: '',
        element: <UnconventionalRedirect hardSection={'raidr'} />,
      },
      {
        path: 'priips-kids-overview',
        element: <KidsOverview />,
      },
      {
        path: 'ucits-kiids-overview-edit',
        element: <KidsOverview />,
      },
      {
        path: 'ucits-kiids-overview-view',
        element: <KidsOverview />,
      },
      {
        path: 'priips-kids-editor',
        element: <KidsEditor />,
      },
      {
        path: 'ucits-kiids-editor',
        element: <KidsEditor />,
      },
      {
        path: 'priips-kids-review',
        element: <KidsReview />,
      },
      {
        path: 'ucits-kiids-review',
        element: <KidsReview />,
      },
      {
        path: 'priips-kids-compare-versions',
        element: <CompareKIDVersions />,
      },
      {
        path: 'ucits-kiids-compare-versions',
        element: <CompareKIDVersions />,
      },
      {
        path: 'priips-kids-view',
        element: <KidsView />,
      },
      {
        path: 'ucits-kiids-view',
        element: <KidsView />,
      },
      {
        path: 'fact-sheets-overview',
        element: <FactSheetsOverview />,
      },
      {
        path: 'fact-sheets-editor',
        element: <FactSheetsEditor />,
      },
      {
        path: 'delegated-acts-overview',
        element: <DelegatedActsOverview />,
      },
      {
        path: 'delegated-acts-editor',
        element: <DelegatedActsEditor />,
      },
      {
        path: 'kids-figures',
        element: <KidsFigures />,
      },
      {
        path: '*',
        element: <PageNotFound />,
      },
    ],
  },
];
