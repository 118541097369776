import TopBar from '../topBar/TopBar';
import { mainColors } from '../../../styling/theme';
import RaptorSnackbar from '../../feedback/raptorSnackbar/Snackbar.component';
import ControlBar from '../controlBar/ControlBar.component';
import { useDispatch, useSelector } from 'react-redux';
import { isControlBarShowingSelector } from '../../../redux/ui/controlbar/selectors';
import makeStyles from '@mui/styles/makeStyles';
import { RaidrKidsProvider } from '../../pages/raidr/priips/context/RaidrKidsContext';
import { Outlet } from 'react-router-dom';
import { clientConfigurationSelector } from '../../../redux/pages/selectors';
import {
  configNameSelector,
  isLoggedInSelector,
} from '../../../redux/auth/selectors';
import axios from 'axios';
import { useEffect } from 'react';
import { fetchClientConfig } from '../../../redux/pages/actions';

const useStyles = makeStyles({
  body: {
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: mainColors.background,
  },
  main: {
    height: 'calc(100% - 6rem)',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  content: {
    height: '100%',
    width: '100%',
    overflow: 'auto',
    padding: '1rem',
  },
});

const RAIDRLayout = () => {
  const classes = useStyles();

  const controlBarIsShowing = useSelector(isControlBarShowingSelector);

  const dispatch = useDispatch();
  const isAuthenticated = useSelector(isLoggedInSelector);

  const configName = useSelector(configNameSelector);

  const cancelToken = axios.CancelToken;
  const source = cancelToken.source();

  useEffect(() => {
    if (isAuthenticated && configName) {
      dispatch(fetchClientConfig(configName, source));
    }
  }, [isAuthenticated]);

  return (
    <RaidrKidsProvider>
      <main className={classes.body}>
        <TopBar />
        {controlBarIsShowing && <ControlBar />}
        <main className={classes.main}>
          <div className={classes.content}>
            <Outlet />
          </div>
        </main>
        <RaptorSnackbar />
      </main>
    </RaidrKidsProvider>
  );
};
export default RAIDRLayout;
