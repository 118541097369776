import React, { FC, useEffect } from 'react';
import { FundInfoComponentProps } from '../../../general/GeneralFundInfoPage';
import useFetchData from '../../../../hooks/useFetchData';
import LawFirstLevelTable from './lawComponents/LawFirstLevelTable';
import {
  formatFundUrl,
  makeUniqueDataKey,
} from '../../../../utilities/urlBuilder';
import {
  addAdditionalTitleBarComponents,
  removeTitleBarComponent,
} from '../../../../redux/pages/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from '../../../../types/redux/pages/PagesStore';
import {
  APPEND_NEW_SELECT_TO_TITLEBAR,
  REMOVE_SELECT_FROM_TITLEBAR,
} from '../../../../types/redux/pages/pageTypes';
import { activePageSelector } from '../../../../redux/pages/selectors';
import { clientNameSelector } from '../../../../redux/auth/selectors';

const UcitsLaw: FC<FundInfoComponentProps> = ({
  fundId,
  fundName,
  positionDate,
}) => {
  const dispatch = useDispatch();
  const activePage = useSelector(activePageSelector);

  // Add the select components to the title bar
  useEffect(() => {
    const selectsToAdd: Select[] = [
      {
        titleBarTitle: 'Data Display: ',
        titleBarKey: 'dataDisplay',
        values: [
          {
            label: 'Current',
            value: 'current',
          },
          {
            label: 'Historical',
            value: 'historical',
          },
        ],
        displayOnOverviewPage: false,
        aValueIsAlwaysSelected: true,
        currentValue: 'current',
      },
    ];

    dispatch(
      addAdditionalTitleBarComponents(
        selectsToAdd,
        APPEND_NEW_SELECT_TO_TITLEBAR,
      ),
    );

    return () => {
      dispatch(
        removeTitleBarComponent('dataDisplay', REMOVE_SELECT_FROM_TITLEBAR),
      );
    };
  }, [activePage]);

  const ucitsComplianceChecklist = useFetchData({
    url: 'compliance_checklist/UCITS',
    keyName: 'compliance_checklist',
    makeFetch: true,
  });
  const basicDataAgg = useFetchData({
    url: formatFundUrl('basic_data_agg', fundId, positionDate),
    keyName: makeUniqueDataKey(fundId, 'basicDataAgg', positionDate), // this is the same as var
    makeFetch: true,
  });

  const ucitsFundData = useFetchData({
    url: formatFundUrl('fund_UCITS_data', fundId, positionDate),
    keyName: makeUniqueDataKey(fundId, 'ucitsFundData', positionDate),
    makeFetch: true,
  });

  return ucitsComplianceChecklist && basicDataAgg && ucitsFundData ? (
    <LawFirstLevelTable
      dataSource={{
        complianceChecklist: ucitsComplianceChecklist,
        basicDataAgg,
        ucitsFundData,
      }}
      activeFundName={fundName}
      activeFund={fundId}
    />
  ) : null;
};

export default UcitsLaw;
