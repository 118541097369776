import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useFetchData from '../../../hooks/useFetchData';
import { updatePage, updateSection } from '../../../redux/pages/actions';
import GeneralComponentErrorShield from '../../general/GeneralComponentErrorShield';
import DashboardContainers from './subComponents/DashboardContainers.component';
import { clientConfigurationSelector } from '../../../redux/pages/selectors';
import { Grid } from '@mui/material';
import { useLoaderData } from 'react-router-dom';

const Dashboard: FC = () => {
  const dispatch = useDispatch();

  const returnData = useLoaderData();

  const clientConfiguration = useSelector(clientConfigurationSelector);

  const data = new FormData();
  data.append('sections', JSON.stringify(clientConfiguration?.sections));

  const DashboardData = useFetchData({
    url: `/dashboard_data`,
    keyName: `dashboard_data`,
    makeFetch: clientConfiguration?.sections ? true : false,
    formData: data,
  });

  useEffect(() => {
    dispatch(updateSection('dashboard'));
    dispatch(updatePage('dashboard', 'dashboard', 'Dashboard'));
  }, []);

  return (
    <GeneralComponentErrorShield
      dataObjects={[DashboardData]}
      customLoadingMessages={['Loading Dashboard Data...']}
      customErrorMessage="No Data Available."
    >
      <Grid container spacing={2} style={{ padding: 8 }}>
        <DashboardContainers inputData={DashboardData} />
      </Grid>
    </GeneralComponentErrorShield>
  );
};

export default Dashboard;
